import { dracula } from "@code-surfer/themes";
import merge from "deepmerge";
import wavesTheme from "gatsby-theme-waves/src/gatsby-plugin-theme-ui/index";
import waves from "./waves";

export default merge.all([
  dracula,
  wavesTheme,
  {
    colors: {
      primary: "#e9e9e9",
    },
    styles: {
      waves,
    },
  },
]);
